import type { Series } from '@mentimeter/http-clients';
import type { SlideDeck } from '@mentimeter/editor-schema/api-types-overrides';

export const seriesReactionsToSlideDeckReactionSettings = (
  series: Series,
): SlideDeck['reactionSettings'] => {
  return {
    reactionsAllowed: series.reactions ?? [],
  };
};
