import { useRequestDone } from '@mentimeter/core-global-state';
import { core, type Regions } from '@mentimeter/http-clients';
import { getRegionBySeriesId } from '@mentimeter/region';
import { useCallback } from 'react';
import { processLegacySeriesData } from './process-legacy-series-data';
import { makeRequest } from './request-wrapper/make-request';
import { fromSnakeKeys } from './snake-keys';
import { useNewEndpoints } from './use-new-endpoints';

interface GetSeriesRequest {
  region: 'eu' | 'us';
  userAuth: string;
  seriesId: string;
}
type SeriesFetcher = (args: GetSeriesRequest) => Request;

const fetchQuestionsWithNewEndpoints = async <TQuestionData>(
  seriesId: string,
  coreApiFetcher: SeriesFetcher,
  region: Regions,
): Promise<TQuestionData> => {
  const response = await fetch(
    makeRequest(coreApiFetcher, { region, seriesId }),
  );

  if (!response.ok) {
    throw new Error(`Failed to fetch series: ${response.status}`);
  }

  const json = await response.json();

  if (json.slide_deck) {
    const camelCaseSlideDeck = fromSnakeKeys(json.slide_deck);
    return camelCaseSlideDeck.slides as TQuestionData;
  } else if (json.slideDeck) {
    /**
     * Object was provided in camelCase by the API.
     */
    return json.slideDeck.slides as TQuestionData;
  } else {
    return processLegacySeriesData(json.series).questions as TQuestionData;
  }
};

const fetchQuestionsWithLegacyEndpoint = async <TQuestionData>(
  seriesId: string,
  region: Regions,
): Promise<TQuestionData> => {
  const response = await core({ region }).series.get(seriesId);
  const series = processLegacySeriesData(response.data);
  return series.questions as TQuestionData;
};

const compatibilityQuestionsFetcher = async <TQuestionData>(
  seriesId: string,
  coreApiFetcher: SeriesFetcher,
  newEndpointsEnabled: boolean,
): Promise<TQuestionData> => {
  const region = getRegionBySeriesId(seriesId);

  if (newEndpointsEnabled) {
    return await fetchQuestionsWithNewEndpoints<TQuestionData>(
      seriesId,
      coreApiFetcher,
      region,
    );
  } else {
    return await fetchQuestionsWithLegacyEndpoint<TQuestionData>(
      seriesId,
      region,
    );
  }
};

const fetchSeriesWithNewEndpoints = async <TSeriesData>(
  seriesId: string,
  coreApiFetcher: SeriesFetcher,
  region: Regions,
): Promise<TSeriesData> => {
  const response = await fetch(
    makeRequest(coreApiFetcher, { region, seriesId }),
  );

  if (!response.ok) {
    throw new Error(`Failed to fetch series: ${response.status}`);
  }

  const json = await response.json();

  if (json.slide_deck) {
    const camelCaseSlideDeck: TSeriesData = fromSnakeKeys(json.slide_deck);
    return camelCaseSlideDeck;
  } else if (json.slideDeck) {
    /**
     * Object was provided in camelCase by the API.
     */
    const camelCaseSlideDeck = json.slideDeck;
    return {
      ...camelCaseSlideDeck,
      slideDeckSegmentations: camelCaseSlideDeck.segmentations,
    } as TSeriesData;
  }

  const series = processLegacySeriesData(json.series);
  return series as TSeriesData;
};

const fetchSeriesWithLegacyEndpoint = async <TSeriesData>(
  seriesId: string,
  region: Regions,
): Promise<TSeriesData> => {
  const response = await core({ region }).series.get(seriesId);
  const series = processLegacySeriesData(response.data);
  return series as TSeriesData;
};

const compatibilitySeriesFetcher = async <TSeriesData>(
  seriesId: string,
  coreApiFetcher: SeriesFetcher,
  newEndpointsEnabled: boolean,
): Promise<TSeriesData> => {
  const region = getRegionBySeriesId(seriesId);

  if (newEndpointsEnabled) {
    return await fetchSeriesWithNewEndpoints<TSeriesData>(
      seriesId,
      coreApiFetcher,
      region,
    );
  } else {
    return await fetchSeriesWithLegacyEndpoint<TSeriesData>(seriesId, region);
  }
};

export const useSlideDeckFetcher = <TSeriesData>(
  seriesId: string,
  coreApiFetcher: SeriesFetcher,
) => {
  const requestDone = useRequestDone();
  const newEndpointsEnabled = true;

  return useCallback(async () => {
    const result = await compatibilitySeriesFetcher<TSeriesData>(
      seriesId,
      coreApiFetcher,
      newEndpointsEnabled,
    );
    requestDone();
    return result;
  }, [coreApiFetcher, newEndpointsEnabled, requestDone, seriesId]);
};

export const useCompatibilitySeriesFetcher = <TSeriesData>(
  seriesId: string,
  coreApiFetcher: SeriesFetcher,
  shouldUseNewEndpoints?: boolean,
) => {
  const requestDone = useRequestDone();
  const defaultNewEndpointsEnabled = useNewEndpoints();
  const newEndpointsEnabled =
    shouldUseNewEndpoints ?? defaultNewEndpointsEnabled;

  return useCallback(async () => {
    const result = await compatibilitySeriesFetcher<TSeriesData>(
      seriesId,
      coreApiFetcher,
      newEndpointsEnabled,
    );
    requestDone();
    return result;
  }, [coreApiFetcher, newEndpointsEnabled, requestDone, seriesId]);
};

export const useCompatibilityQuestionFetcher = <TQuestionData>(
  seriesId: string,
  coreApiFetcher: SeriesFetcher,
  shouldUseNewEndpoints?: boolean,
) => {
  const requestDone = useRequestDone();
  const defaultNewEndpointsEnabled = useNewEndpoints();
  const newEndpointsEnabled =
    shouldUseNewEndpoints ?? defaultNewEndpointsEnabled;

  return useCallback(async () => {
    const result = await compatibilityQuestionsFetcher<TQuestionData>(
      seriesId,
      coreApiFetcher,
      newEndpointsEnabled,
    );
    requestDone();
    return result;
  }, [coreApiFetcher, newEndpointsEnabled, requestDone, seriesId]);
};

export const useSlideFetcher = <TQuestionData>(
  seriesId: string,
  coreApiFetcher: SeriesFetcher,
) => {
  const requestDone = useRequestDone();
  const newEndpointsEnabled = true;

  return useCallback(async () => {
    const result = await compatibilityQuestionsFetcher<TQuestionData>(
      seriesId,
      coreApiFetcher,
      newEndpointsEnabled,
    );
    requestDone();
    return result;
  }, [coreApiFetcher, newEndpointsEnabled, requestDone, seriesId]);
};
