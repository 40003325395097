export function toSnakeKeys(obj: any): any {
  if (typeof obj !== 'object' || obj === null) {
    return obj;
  }

  if (Array.isArray(obj)) {
    return obj.map((item) => toSnakeKeys(item));
  }

  return Object.keys(obj).reduce((acc, key) => {
    const snakeKey = key.replace(
      /[A-Z]/g,
      (letter) => `_${letter.toLowerCase()}`,
    );
    acc[snakeKey] = toSnakeKeys(obj[key]);
    return acc;
  }, {} as any);
}

export const fromSnakeKeys = (obj: any): any => {
  if (typeof obj !== 'object' || obj === null) {
    return obj;
  }

  if (Array.isArray(obj)) {
    return obj.map((item) => fromSnakeKeys(item));
  }

  return Object.keys(obj).reduce((acc, key) => {
    const camelKey = key.replace(/(_[a-z])/g, (group) =>
      group.toUpperCase().replace('_', ''),
    );
    acc[camelKey] = fromSnakeKeys(obj[key]);
    return acc;
  }, {} as any);
};
