import type { Series } from '@mentimeter/http-clients';
import type { SlideDeck } from '@mentimeter/editor-schema/api-types-overrides';
import {
  QA_ENABLED_ALL_SLIDES,
  QA_ENABLED_QA_SLIDES_ONLY,
  QA_MODERATED,
  QA_NOT_MODERATED,
  QA_QUESTION_VISIBLE_MODERATORS_ONLY,
  QA_QUESTION_VISIBLE_PUBLIC,
} from '../../../constants';

export const seriesQaPropertiesToQaSettings = (
  series: Series,
): SlideDeck['qaSettings'] => {
  return {
    enablementScope: series.qfa_active
      ? QA_ENABLED_ALL_SLIDES
      : QA_ENABLED_QA_SLIDES_ONLY,
    moderationPolicy: series.qfa_moderation_enabled
      ? QA_MODERATED
      : QA_NOT_MODERATED,
    questionVisibility: series.qfa_intercom_enabled
      ? QA_QUESTION_VISIBLE_PUBLIC
      : QA_QUESTION_VISIBLE_MODERATORS_ONLY,
    moderationAccessKey: series.qfa_moderation_access_key ?? null,
  };
};
