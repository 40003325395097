import type { SlideDeck as EditorSlideDeck } from '@mentimeter/editor-schema/api-types-overrides';
import type { Series, VotingSeries } from '@mentimeter/http-clients';
import type { SlideDeck as PowerPointSlideDeck } from '@mentimeter/integrations-schema/power-point';
import type { SlideDeck as PresentationSlideDeck } from '@mentimeter/presentation-schema/api-types-overrides';
import { getUnmigratedSlideDeckProperty } from '../../forward-compatibility/get-unmigrated-slidedeck-property';
import { InvalidProxySetError } from '../../utils/invalid-proxy-set-error';

type BaseCompatibilityLayerHandlerTarget =
  | Series
  | VotingSeries
  | EditorSlideDeck
  | PresentationSlideDeck
  | PowerPointSlideDeck;

export const baseSeriesCompatibilityLayerHandler = <
  TTarget extends BaseCompatibilityLayerHandlerTarget,
>({
  getMigratedSeriesProperty,
}: {
  getMigratedSeriesProperty: (
    target: TTarget,
    key: keyof Series | 'slides', // Slides is nested property which needs to be manually wrapped in a proxy
  ) => any;
}): ProxyHandler<TTarget> => ({
  get(
    target: TTarget,
    key:
      | keyof BaseCompatibilityLayerHandlerTarget
      | '__isProxy'
      | '__target'
      | 'isMigrated',
  ) {
    if (key === '__isProxy') {
      return true;
    }

    if ((key as any) === '__target') {
      return target;
    }

    if (key === 'isMigrated') {
      return Array.isArray((target as EditorSlideDeck)?.slides);
    }

    if (
      (target as EditorSlideDeck | PresentationSlideDeck | PowerPointSlideDeck)
        .slideDeckId
    ) {
      return getMigratedSeriesProperty(target, key as any);
    }

    return getUnmigratedSlideDeckProperty(target as Series, key as any);
  },
  set(_: never, key: string) {
    throw new InvalidProxySetError(key, 'series');
  },
});
