'use client';

import { createContext, useContext } from 'react';

export interface MigrationStatusContextProps {
  isSlideDeckMigrated: boolean;
}

export const MigrationStatusContext =
  createContext<MigrationStatusContextProps>({
    isSlideDeckMigrated: false,
  });

export const useIsSlideDeckMigrated = () => {
  const context = useContext(MigrationStatusContext);
  return context?.isSlideDeckMigrated ?? false;
};
