import type { SlideDeck } from '@mentimeter/editor-schema/api-types-overrides';
import type { Series } from '@mentimeter/http-clients';
import {
  SLIDE_DECK_PARTICIPATION_RESPONSE_MODE_ACCEPTING_RESPONSES,
  SLIDE_DECK_PARTICIPATION_RESPONSE_MODE_RESPONSES_BLOCKED,
} from '../../../constants';

type ParticipationSettings = SlideDeck['participationSettings'];

export const seriesClosedForVotingToSlideDeckParticipationResponseMode = (
  closedForVoting: Series['closed_for_voting'],
): ParticipationSettings['participationResponseMode'] => {
  return closedForVoting
    ? SLIDE_DECK_PARTICIPATION_RESPONSE_MODE_RESPONSES_BLOCKED
    : SLIDE_DECK_PARTICIPATION_RESPONSE_MODE_ACCEPTING_RESPONSES;
};
