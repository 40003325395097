import type { Series } from '@mentimeter/http-clients';
import type { SlideDeck as EditorSlideDeck } from '@mentimeter/editor-schema/api-types-overrides';
import type { SlideDeck as PresentationSlideDeck } from '@mentimeter/presentation-schema/api-types-overrides';

export const musicSettingsToSeriesMusicUrl = (
  musicSettings:
    | EditorSlideDeck['musicSettings']
    | PresentationSlideDeck['musicSettings'],
): Series['music_url'] => {
  if (musicSettings.playbackPolicy === 'quiz-enabled') {
    return musicSettings.url;
  }

  return '';
};
