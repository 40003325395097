import type { Series } from '@mentimeter/http-clients';
import type { SlideDeck } from '@mentimeter/editor-schema/api-types-overrides';

export const seriesLanguageToLanguageSettings = (
  series: Series,
): SlideDeck['languageSettings'] => {
  return {
    presentationLanguage:
      (series.language as SlideDeck['languageSettings']['presentationLanguage']) ??
      'en',
  };
};
