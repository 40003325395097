import type { Series } from '@mentimeter/http-clients';
import type { SlideDeck } from '@mentimeter/editor-schema/api-types-overrides';

export const seriesProfanityLangToProfanitySettings = (
  series: Series,
): SlideDeck['profanitySettings'] => {
  return {
    profanityFilters:
      series.profanity_lang === ''
        ? []
        : ((series.profanity_lang?.split(
            ',',
          ) as SlideDeck['profanitySettings']['profanityFilters']) ?? []),
  };
};
