import type { Series } from '@mentimeter/http-clients';
import type { SlideDeck } from '@mentimeter/presentation-schema/api-types-overrides';

export const seriesPresentationTimeToPresentationTimer = (
  series: Series,
): SlideDeck['presentationTimer'] => {
  if (!series.presentation_time) {
    return {
      presentationStartedAt: 0,
    };
  }
  return { presentationStartedAt: series.presentation_time };
};
