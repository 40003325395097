import type { Series } from '@mentimeter/http-clients';
import type { SlideDeck } from '@mentimeter/editor-schema/api-types-overrides';
import {
  QA_ENABLED_ALL_SLIDES,
  QA_MODERATED,
  QA_QUESTION_VISIBLE_PUBLIC,
} from '../../../constants';

type LegacyQaSettings = Pick<
  Series,
  | 'qfa_moderation_enabled'
  | 'qfa_active'
  | 'qfa_intercom_enabled'
  | 'qfa_moderation_access_key'
>;

export const qaSettingsToSeriesQaProperties = (
  qaSettings:
    | (Omit<SlideDeck['qaSettings'], 'moderationAccessKey'> & {
        moderationAccessKey?: string | undefined | null;
      })
    | undefined,
): LegacyQaSettings => {
  if (qaSettings === undefined) {
    return {
      qfa_active: false,
      qfa_intercom_enabled: false,
      qfa_moderation_enabled: false,
      qfa_moderation_access_key: undefined as unknown as string,
    };
  }

  return {
    qfa_active: qaSettings.enablementScope === QA_ENABLED_ALL_SLIDES,
    qfa_intercom_enabled:
      qaSettings.questionVisibility === QA_QUESTION_VISIBLE_PUBLIC,
    qfa_moderation_enabled: qaSettings.moderationPolicy === QA_MODERATED,
    qfa_moderation_access_key:
      qaSettings.moderationAccessKey ?? (undefined as unknown as string),
  };
};
