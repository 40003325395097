import type { Series } from '@mentimeter/http-clients';
import type {
  SeriesWithSlideDeck as PresentationSeriesWithSlideDeck,
  SlideDeck as PresentationSlideDeck,
} from '@mentimeter/presentation-schema/api-types-overrides';
import { isProxyInstance } from '../../../utils/is-proxy-instance';
import { baseSeriesCompatibilityLayerHandler } from '../../common/base-compatibility-layer-handler';
import { createPresentationSlideCompatibilityLayer } from '../slide-compatibility-layer/create-presentation-slide-compatibility-layer';
import { getOrSetCache, type WithCache } from '../../../utils/get-or-set-cache';
import { propertyToSeriesProperty as slideDeckPropertyToSeriesProperty } from '../../../backward-compatibility/converters/slide-deck/property-to-series-property';

export const createPresentationSeriesCompatibilityLayer = (
  target: Series | PresentationSlideDeck | undefined | null,
): PresentationSeriesWithSlideDeck | undefined => {
  if (!target) {
    return undefined;
  }

  if (isProxyInstance(target)) {
    return target as PresentationSeriesWithSlideDeck;
  }

  return new Proxy(
    target,
    baseSeriesCompatibilityLayerHandler<PresentationSlideDeck>({
      getMigratedSeriesProperty: (target, key) => {
        if (key === 'presentation_time') {
          return target.presentationTimer.presentationStartedAt;
        }

        if (key === 'slides') {
          const slides = (
            target.slides as Array<
              (typeof target.slides)[number] & { is_migrated: boolean }
            >
          ).map((slide) => createPresentationSlideCompatibilityLayer(slide));
          return slides;
        }

        if (key === 'questions') {
          const questions = target.slides.map((slide) =>
            createPresentationSlideCompatibilityLayer(slide),
          );

          const cacheKey = JSON.stringify(questions);
          return getOrSetCache(
            target as WithCache<PresentationSlideDeck>,
            'questions',
            cacheKey,
            questions,
          );
        }

        return slideDeckPropertyToSeriesProperty(target, key);
      },
    }),
  ) as PresentationSeriesWithSlideDeck;
};
