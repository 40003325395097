import { useSplits } from '@mentimeter/splitio';
import Cookies from '@mentimeter/cookies';
import memoize from 'lodash/memoize';

const hasOverrideCookie = memoize(
  () => Cookies.get('MX_NEW_ENDPOINTS') === 'true',
);

export const useNewEndpoints = (): boolean => {
  const { MX_Editor_Endpoints } = useSplits(['MX_Editor_Endpoints']);
  return (
    MX_Editor_Endpoints === 'on' ||
    (MX_Editor_Endpoints !== 'off' && hasOverrideCookie())
  );
};
