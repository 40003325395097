import type { Series } from '@mentimeter/http-clients';
import type { SlideDeck } from '@mentimeter/editor-schema/api-types-overrides';
import { LIVE_CHAT_ENABLED } from '../../../constants';

export const liveChatSettingsToSeriesCfaProperties = (
  liveChatSettings: SlideDeck['liveChatSettings'],
): Pick<Series, 'comments_enabled'> => {
  return {
    comments_enabled: liveChatSettings?.liveChatMode === LIVE_CHAT_ENABLED,
  };
};
