import { type Series } from '@mentimeter/http-clients';

export function processLegacySeriesData(seriesData: Series): Series {
  return {
    ...seriesData,
    questions: (seriesData.questions || []).map((question) => ({
      ...question,
      frontendCompatibilityData: { seriesTheme: seriesData.theme },
    })),
  };
}
