import type { Series } from '@mentimeter/http-clients';
import type { SlideDeck } from '@mentimeter/editor-schema/api-types-overrides';
import { LIVE_CHAT_DISABLED, LIVE_CHAT_ENABLED } from '../../../constants';

export const seriesCfaPropertiesToLiveChatSettings = (
  series: Series,
): SlideDeck['liveChatSettings'] => {
  return {
    liveChatMode: series.comments_enabled
      ? LIVE_CHAT_ENABLED
      : LIVE_CHAT_DISABLED,
  };
};
