import type { Series } from '@mentimeter/http-clients';
import type { Segmentation } from '@core-api/editor/types/response';

export function seriesSegmentationToSlideDeckSegmentation(
  segmentations: Series['segmentations'] | undefined,
): Array<Segmentation> {
  if (!segmentations) {
    return [];
  }

  return Object.entries(segmentations).map<Segmentation>(
    ([targetInteractiveContentId, sourceInteractiveContentId]) => {
      return {
        targetInteractiveContentId,
        sourceInteractiveContentId,
      };
    },
  );
}
