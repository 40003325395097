import type { Series } from '@mentimeter/http-clients';
import type { SlideDeck } from '@mentimeter/editor-schema/api-types-overrides';

export const seriesMusicUrlToMusicSettings = (
  series: Series,
): SlideDeck['musicSettings'] => {
  if (!series.music_url) {
    return {
      playbackPolicy: 'disabled',
      url: '',
    };
  }

  return {
    playbackPolicy: 'quiz-enabled',
    url: series.music_url ?? '',
  };
};
