import { snakeCase } from 'lodash';

function isObject(value: any): value is Record<string, any> {
  return value && typeof value === 'object' && !Array.isArray(value);
}

export function convertKeysToSnakeCase(obj: any): any {
  if (!isObject(obj)) {
    return obj;
  }

  const newObj: Record<string, any> = {};

  Object.keys(obj).forEach((key) => {
    const newKey = snakeCase(key);
    const value = obj[key];

    if (isObject(value)) {
      newObj[newKey] = convertKeysToSnakeCase(value);
    } else if (Array.isArray(value)) {
      newObj[newKey] = value.map((item) => convertKeysToSnakeCase(item));
    } else {
      newObj[newKey] = value;
    }
  });

  return newObj;
}
