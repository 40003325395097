import { create } from 'zustand';
import { useEffect, useRef } from 'react';
import { useParams, useSearchParams } from '@mentimeter/next-navigation';

interface IdStore {
  seriesId: string;
  questionId: string | undefined;
  getQuestionId: () => string | undefined;
  getSeriesId: () => string;
  /**
   * Sets the current `seriesId`. NOTE: Do not use this to try to navigate to a series
   */
  setSeriesId: (seriesId: string) => void;
  /**
   * Sets the current `questionId`. NOTE: Do not use this to try to navigate to a question
   */
  setQuestionId: (questionId: string | undefined) => void;
}

export const useIdStore = create<IdStore>((set, get) => ({
  seriesId: '',
  questionId: undefined,
  getQuestionId: () => get().questionId,
  getSeriesId: () => get().seriesId,
  setSeriesId: (seriesId) => set({ seriesId }),
  setQuestionId: (questionId) => set({ questionId }),
}));

export const useActiveSeriesId = () => useIdStore((state) => state.seriesId);
export const useActiveQuestionId = (): string =>
  useIdStore((state) => state.questionId) as string;

export const useAppRouterIds = (ids?: {
  seriesId?: string | undefined;
  questionId?: string | undefined;
}) => {
  const mounted = useRef(false);
  const params = useParams();
  const search = useSearchParams();
  let seriesId: string | undefined = ids?.seriesId;
  let questionId: string | undefined = ids?.questionId;

  if (params.seriesId && search.get('question')) {
    seriesId = params.seriesId as string;
    questionId = search.get('question') as string;
  }

  const setSeriesId = useIdStore((state) => state.setSeriesId);
  const setQuestionId = useIdStore((state) => state.setQuestionId);

  if (seriesId && !mounted.current) {
    setSeriesId(seriesId);
    setQuestionId(questionId);
  }

  useEffect(() => {
    if (seriesId) {
      setSeriesId(seriesId);
    }
    setQuestionId(questionId);
    mounted.current = true;
  }, [questionId, seriesId, setQuestionId, setSeriesId]);

  return { seriesId, questionId };
};
