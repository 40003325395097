import type { Series } from '@mentimeter/http-clients';
import type { SlideDeck as EditorSlideDeck } from '@mentimeter/editor-schema/api-types-overrides';
import type { SlideDeck as PresentationSlideDeck } from '@mentimeter/presentation-schema/api-types-overrides';

export const participationSettingsToSeriesParticipationIdentityMode = (
  participationSettings:
    | EditorSlideDeck['participationSettings']
    | PresentationSlideDeck['participationSettings']
    | undefined,
): Series['participation_identity_mode'] => {
  return participationSettings?.participationIdentityMode ?? 'anonymous';
};
