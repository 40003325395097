import type { SlideDeck as EditorSlideDeck } from '@mentimeter/editor-schema/api-types-overrides';
import type { SlideDeck as PresentationSlideDeck } from '@mentimeter/presentation-schema/api-types-overrides';
import type { Series } from '@mentimeter/http-clients';
import { PARTICIPATION_POLICY_NO_RESTRICTION } from '../../../constants';

export const participationIdentityModeSettingsToSeriesVoteAgainEnabled = (
  participationSettings:
    | EditorSlideDeck['participationSettings']
    | PresentationSlideDeck['participationSettings']
    | undefined,
): Series['vote_again_enabled'] => {
  return (
    participationSettings?.participationPolicy ===
    PARTICIPATION_POLICY_NO_RESTRICTION
  );
};
