import type { Series } from '@mentimeter/http-clients';
import type { SlideDeck as EditorSlideDeck } from '@mentimeter/editor-schema/api-types-overrides';
import type { SlideDeck as PresentationSlideDeck } from '@mentimeter/presentation-schema/api-types-overrides';
import type { SlideDeck as PowerPointSlideDeck } from '@mentimeter/integrations-schema/power-point';

type SlideDeck = EditorSlideDeck | PresentationSlideDeck | PowerPointSlideDeck;

export function segmentationsToSeriesSegmentations(
  slides: SlideDeck['slides'] | undefined,
  segmentations: SlideDeck['segmentations'] | undefined,
): Series['segmentations'] {
  if (!Array.isArray(slides) || !Array.isArray(segmentations)) {
    return {};
  }

  return segmentations.reduce<Series['segmentations']>(
    (acc, { targetInteractiveContentId, sourceInteractiveContentId }) => {
      const findLegacyQuestionAdminKey = (id: string) => {
        return slides.find((slide) => {
          return slide.interactiveContents[0]?.interactiveContentId === id;
        })?.legacyQuestionAdminKey;
      };

      const targetLegacyQuestionAdminKey = findLegacyQuestionAdminKey(
        targetInteractiveContentId,
      );

      const sourceLegacyQuestionAdminKey = findLegacyQuestionAdminKey(
        sourceInteractiveContentId,
      );

      if (!targetLegacyQuestionAdminKey || !sourceLegacyQuestionAdminKey) {
        return acc;
      }

      return {
        ...acc,
        [targetLegacyQuestionAdminKey]: sourceLegacyQuestionAdminKey,
      };
    },
    {},
  );
}
